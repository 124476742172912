import { SliceZone } from "@prismicio/react"
import { Layout, SEO, sliceComponents, sliceComponentV2 } from "components"
import { graphql } from "gatsby"
import * as React from "react"
import "./index.scss"

interface IIndexPageProps {
  data: any
}

const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {
  if (!data) {
    return null
  }
  const doc = data.prismicTeamblev2Homepage.data

  return (
    <Layout isHomePage>
      <SEO {...doc.body1[0].primary} />
      <SliceZone
        slices={doc.body}
        components={sliceComponentV2}
        defaultComponent={() => null}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    prismicTeamblev2Homepage {
      data {
        body1 {
          ... on PrismicTeamblev2homepageDataBody1GeneralCard {
            id
            primary {
              description
              title
            }
          }
        }
        body {
          ... on PrismicTeamblev2homepageDataBodyTeamblev2herosection {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyClientTestimonials {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyFeatured {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyVideo {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyBottombanner {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyAdvisorbanner {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyOperationsBanners {
            id
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyProductFeatureSection {
            slice_type
          }
          ... on PrismicTeamblev2homepageDataBodyCaseStudy {
            id
            slice_type
          }
          ...TeambleV2HeroSectionData
          ...clientTestimonialsV2
          ...productFeatured
          ...videoSectionTestimonial
          ...bottomBanner
          ...operationsBanner
          ...advisoryBanner
          ...caseStudyData
        }
      }
    }
  }
`

export default IndexPage
